import { useState, useEffect } from 'react';

const useDebounce = ( value, delay ) => {

  const [ debouncedValue, setDebouncedValue ] = useState( value );

  useEffect( () => {

    const timeoutId = setTimeout( () => {
      setDebouncedValue( value );
    }, delay );

    return () => clearTimeout( timeoutId );

  }, [ value, delay ] );

  return debouncedValue;

};

export default useDebounce;