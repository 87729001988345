import React from 'react';
import ReactDOM from 'react-dom/client';
import './globals.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';


const root = ReactDOM.createRoot( document.getElementById( 'root' ) );

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={ "985486628434-39842ij9vkr5fh3rq7jaaljqaodah0pi.apps.googleusercontent.com" }>
      <App />
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
